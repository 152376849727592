<template>
  <div class="record-index">

    <div class="Stat">
      <ul>
          <li>
            <div>
              <p class="StatNum">{{StatData.StoryNum}}</p>
              <p class="StatText">故事数</p>
            </div>
          </li>
          <li>
            <div>
              <p class="StatNum">{{StatData.ShareNum}}</p>
              <p class="StatText">共享</p>
            </div>
          </li>
          <li>
            <div>
              <p class="StatNum">{{StatData.PendingNum}}</p>
              <p class="StatText">待定</p>
            </div>
          </li>
          <li>
            <div>
              <p class="StatNum">{{StatData.SelectedNum}}</p>
              <p class="StatText">精选</p>
            </div>
          </li>
          <li>
            <div>
              <p class="StatNum">{{StatData.SpareNum}}</p>
              <p class="StatText">备用</p>
            </div>
          </li>
      </ul>

    </div>

    <div class="header flex">
      <div>
        <!--<div style="display:inline-block;position: relative">-->
        <!--<person class="person" v-bind="personObj" @getData="_relevanceObject"></person>-->
        <!--<a-input v-model="relevanceText" placeholder="区域"></a-input>-->
        <!--</div>-->

        <a-select
          style="width: 150px;"
          placeholder="共享素材"
          @change="_changeShare"
          allowClear
        >
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="1">是</a-select-option>
          <a-select-option value="0">否</a-select-option>
        </a-select>

        <a-select
          style="width: 150px;margin-left: 15px"
          placeholder="素材标签"
          @change="_changeLabel"
          allowClear
        >
          <a-select-option value="0">全部</a-select-option>
          <a-select-option value="1">精选</a-select-option>
          <a-select-option value="2">共享</a-select-option>
          <a-select-option value="3">备用</a-select-option>
          <a-select-option value="4">未共享</a-select-option>
        </a-select>

        <!-- <a-date-picker
          style="width: 150px;margin-left: 15px"
          v-model="RadioDate"
          @change="_RadioDate"
          placeholder="播放时间"
        /> -->

        <a-select
          style="width: 150px;margin-left: 15px"
          placeholder="年龄段"
          v-model="age"
          @change="_changeAge"
          allowClear
        >
          <a-select-option value="all">全部</a-select-option>
          <a-select-option value="0-2">0-2</a-select-option>
          <a-select-option value="2-4">2-4</a-select-option>
          <a-select-option value="4-7">4-7</a-select-option>
        </a-select>

        <!-- <a-cascader
          style="width: 150px;margin-left: 15px"
          :options="ageList"
          v-model="age"
          @change="_changeAge"
          placeholder="年龄段"
          allowClear
        /> -->

        <a-input
          placeholder="搜索姓名或故事主题"
          style="width: 150px;margin-left: 15px"
          v-model="searchInput"
        />

        <a-button style="margin-left: 15px;" @click="_reset">重置</a-button>
        <a-button type="primary" style="margin-left: 15px;" @click="_search"
          >查询</a-button
        >
      </div>

      <div>
        <a-button type="primary" style="margin-left: 15px;" @click="_addShow"
          >新建故事</a-button
        >
      </div>
    </div>

    <div class="container">
      <a-table
        bordered
        :columns="tableColumns"
        :dataSource="tableData"
        :pagination="false"
      >
        <div slot="audioFile" slot-scope="text, record, index">
          <span
            @click="_skipAudio(record.RadioUrl)"
            style="color: #1890FF;cursor: pointer"
            >打开>></span
          >
        </div>
        <div slot="state" slot-scope="text, record, index">
          <span
            class="state-icon"
            :style="text == '已发布' ? 'background: #75C255' : ''"
          ></span>
          <span>{{ text }}</span>
        </div>
        <div slot="operation" slot-scope="text, record, index">
          <i
            class="iconfont icon-bianji icon-btn"
            style="font-size: 20px"
            @click.stop="_setItem(index)"
          ></i>
          <a-popconfirm
            title="您确定要删除吗?"
            @confirm="_delTableRow(index)"
            okText="确定"
            cancelText="取消"
          >
            <i class="iconfont icon-shanchu- icon-btn"></i>
          </a-popconfirm>
        </div>
        <div slot="Label" slot-scope="text, record, index">
          <!-- <a-popover placement="bottomRight">
            <template slot="content">
              <p>Content</p>
              <p>Content</p>
            </template>
            <template slot="title">
              <span>Title</span>
            </template>
            <a-button>BR</a-button>
        </a-popover> -->
          <a-popover placement="bottomLeft">
            <div class="Label" :style="{ background: record.LabelBgColor }"><span :style=" { color: record.LabelColor }">{{record.LabelText}}</span></div>
            <template slot="content">
              <div class="status-list">
                <div style="margin-top:10px">素材标签</div>
                <div @click="_setLabel(record,index,1)">
                  <span style="border-color: #f59336"><i v-if="record.Label == 1" style="background: #f59336"></i></span>
                  <p>精选</p>
                </div>
                <div @click="_setLabel(record,index,2)">
                  <span style="border-color: #3699ff"><i v-if="record.Label == 2" style="background: #3699ff"></i></span>
                  <p>待定</p>
                </div>
                <div @click="_setLabel(record,index,3)">
                  <span style="border-color: #bfbfbf"><i v-if="record.Label == 3" style="background: #bfbfbf"></i></span>
                  <p>备用</p>
                </div>
                <!-- <div @click="_setLabel(record,index,4)">
                  <span style="border-color: #bfbfbf"><i v-if="record.Label == 4" style="background: #bfbfbf"></i></span>
                  <p>未共享</p>
                </div> -->
              </div>
          </template>
        </a-popover>
        </div>
      </a-table>
      <div style="margin: 20px 0;text-align: right" v-if="count > 10">
        <a-pagination
          showQuickJumper
          :defaultCurrent="1"
          :total="count"
          @change="_PageChange"
        />
      </div>
    </div>

    <a-drawer
      :title="setId ? '修改故事' : '新建故事'"
      placement="right"
      width="700"
      :closable="false"
      @close="_onClose"
      :visible="visible"
    >
      <div style="padding-bottom: 60px">
        <p class="row">
          <span class="label">故事主题</span>
          <a-input
            style="width: 350px;margin-left: 15px;"
            v-model="RadioName"
            placeholder="请输入"
          />
        </p>
        <!-- <p class="row">
                    <span class="label">播放时间</span>
                    <a-date-picker style="width: 350px;margin-left: 15px" v-model="AddRadioDate" placeholder="请选择"/>
                </p>
                <p class="row">
                    <span class="label">发布状态</span>
                    <a-select style="width: 350px;margin-left: 15px" v-model="IssueStatus">
                        <a-select-option value="未发布">未发布</a-select-option>
                        <a-select-option value="已发布">已发布</a-select-option>
                    </a-select>
                </p> -->
        <p class="row">
          <span class="label">年龄段</span>
          <a-select
            style="width: 350px;margin-left: 15px"
            placeholder="请选择年龄段"
            v-model="addAge"
            @change="_changeAddAge"
            :options="ageOptionsList"
          >
          </a-select>
        </p>
        <div class="row">
          <span class="label" style="vertical-align: top;">故事内容</span>
          <div
            style="height:200px;width:480px;display: inline-block;margin-left: 15px"
          >
            <div id="websiteEditorElem" style="width:100%;height:100%"></div>
          </div>
        </div>
        <p class="row">
          <span class="label">背景音乐</span>
          <a-select style="width: 350px;margin-left: 15px" v-model="BgMusic">
            <a-select-option value="">无</a-select-option>
            <a-select-option
              :value="item.FileUrl"
              v-for="(item, index) in BgMusicList"
              :key="index"
              >{{ item.FileName }}</a-select-option
            >
          </a-select>
        </p>
        <div class="row">
          <span class="label">音频内容</span>
          <div style="margin-left: 15px;display: inline-block;color: #ABB0BF">
            格式支持mp3、wma、wav、amr、m4a，文件大小不超过200M，音频时长不超过1小时
          </div>
        </div>

        <div class="row">
          <span class="label"></span>
          <div style="margin-left: 15px;display: inline-block;color: #ABB0BF">
            <a-button style="position: relative"
              >上传素材
              <input
                type="file"
                id="file"
                accept="audio/*"
                @change="_uploadFile($event)"
                style="position: absolute;top:0;left:0;width:100%;height:100%;opacity: 0"
            /></a-button>

            <a-button
              style="position: relative;margin-left: 20px;"
              @click="_onMaterialShow()"
              >选择素材</a-button
            >
          </div>
        </div>
        <div class="row" v-if="uploadFile">
          <div
            class="audio"
            style="border: 1px solid #D9D9D9;width:280px;margin-left: 84px"
          >
            <div class="audio-file" style="margin-right: 10px">
              <div style="padding: 10px 0">
                <a-icon
                  type="customer-service"
                  style="font-size: 35px;color: #1890FF;"
                />
              </div>
            </div>
            <div class="audio-title" style="position: relative">
              {{ uploadFile[0].FileName }}
              <span
                style="position: absolute;left:0;bottom:0;color: #666;font-size: 14px"
                >{{ uploadFile[0].Duration }}</span
              >
            </div>
            <i
              class="iconfont icon-shanchu- icon-btn"
              style="position: absolute;bottom: 10px;right: 10px;"
              @click="_delAccessory"
            ></i>
          </div>
        </div>

        <div class="row" v-if="isShareShow">
          <span class="label">是否共享</span>
          <div style="margin-left: 15px;display: inline-block;color: #ABB0BF">
            <a-switch
              @change="onChangeCheck"
              v-model="isShareChecked"
              :disabled="ShareDisabled"
            ></a-switch>
          </div>
        </div>
      </div>
      <div class="alert-footer">
        <a-button style="margin-right:25px" @click.stop="_onClose"
          >取消</a-button
        >
        <a-button type="primary" @click.stop="_save">{{
          setId ? '修改' : '确定'
        }}</a-button>
      </div>

      <a-drawer
        title="素材列表"
        placement="right"
        width="900"
        :closable="false"
        @close="_onMaterialClose"
        :visible="materialVisible"
      >
        <MaterialList @fathermethod="_materialVisible"></MaterialList>
      </a-drawer>
    </a-drawer>
  </div>
</template>

<script>
import E from 'wangeditor'
import person from 'components/SelectPersonnel/SelectPersonnel' //选人
import MaterialList from 'components/Common/MaterialList' //选择素材
const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
export default {
  name: 'record-index',
  components: { E, person, MaterialList },
  data () {
    return {
      ageList: [],
      tableColumns: [
        {
          title: '序号',
          dataIndex: 'sNumber',
          width: 70,
          align: 'center'
        },
        {
          title: '故事主题',
          dataIndex: 'RadioName',
          align: 'center'
        },
        // {
        //   title: '播放时间',
        //   dataIndex: 'RadioDate',
        //   align: 'center'
        // },
        {
          title: '年龄段',
          dataIndex: 'Age',
          align: 'center'
        },
        {
          title: '素材标签',
          dataIndex: 'Label',
          align: 'center',
          scopedSlots: { customRender: 'Label' }
        },
        {
          title: '共享素材',
          dataIndex: 'isShare',
          align: 'center',
          scopedSlots: { customRender: 'isShare' }
        },
        {
          title: '状态',
          dataIndex: 'State',
          align: 'center',
          scopedSlots: { customRender: 'state' }
        },
        {
          title: '音频文件',
          dataIndex: 'AudioFile',
          align: 'center',
          scopedSlots: { customRender: 'audioFile' }
        },
        {
          title: '上传时间',
          dataIndex: 'CreateTime',
          align: 'center'
        },
        {
          title: '上传人',
          dataIndex: 'CreateUser',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 120,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      tableData: [],
      visible: false,
      phoneEditor: '',
      personObj: {
        rank: 0, //职级 0为不选，1为选
        nums: 10000, //选的数量
        department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
        contacts: '', //常用联系人 不要常用联系人传空''
        selectArr: this.relevanceObject //要传入的数据
      },
      relevanceObject: '',
      RadioDate: null,
      age: [],
      count: 0,
      phoneEditorHtml: '',
      setId: '',
      BgMusicList: [],
      relevanceText: '',

      uploadFile: '', //音频文件
      RadioName: '', //主题名称
      AddRadioDate: null, //播放日期
      Remarks: '', //故事的内容
      addAge: [],
      duration: 0,
      searchInput: '', //创建人搜索

      IssueStatus: '已发布',
      BgMusic: '',
      isShare: '',
      isShareChecked: false,
      materialVisible: false,
      uid: '', //当前人id
      isShareShow: true,
      ShareDisabled: false,
      ageOptionsList: [
        {
          value: '0-2',
          title: '0-2岁'
        },
        {
          value: '2-4',
          title: '2-4岁'
        },
        {
          value: '4-7',
          title: '4-7岁'
        }
      ],
      Label:0,
      StartAge:'',
      EndAge:'',
      StatData:{},
      defaultStatData:{
        StoryNum:0,
        ShareNum:0,
        PendingNum:0,
        SelectedNum:0,
        SpareNum:0
      },
    }
  },
  props: {
    areaid: {
      type: String
    }
  },
  watch: {
    areaid: function (data) {
      if (this.$parent.tabsActive == 2) {
          this.relevanceText = data
          this._search()
      }
    }
  },
  created () {
    let self = this
    let user = JSON.parse(window.sessionStorage.getItem('userInfo'))
    self.uid = user.uid
    for (let i = 0; i < 9; i++) {
      self.ageList.push({ value: i, label: i, children: [] })
      for (let j = i + 1; j < 9; j++) {
        self.ageList[i].children.push({ value: j, label: j })
      }
    }
    this.$axios.get(5836147, {}, res => {
      if (res.data.code == 1) {
        this.BgMusicList = res.data.data
      }
    })

    this._infoData(1)
  },
  methods: {
    _materialVisible (data) {
      this.materialVisible = false
      if (data) {
        if (data.CreateUserId != this.uid) {
          this.isShareChecked = false
          this.ShareDisabled = true
        }
        data.RadioInfo.create_id = data.CreateUserId
        this.uploadFile = [data.RadioInfo]
        console.log(this.uploadFile)
      }
    },
    _skipAudio (audio) {
      window.open(audio)
    },
    _delAccessory () {
      this.uploadFile = ''
      this.duration = ''
      this.ShareDisabled = false
    },
    _setItem (i) {
      this.$axios.get(5836142, { Id: this.tableData[i].Id }, res => {
        if (res.data.code == 1) {
          this._addShow()
          this.setId = i
          this.RadioName = res.data.data.RadioName
          this.uploadFile = res.data.data.RadioInfo ? [res.data.data.RadioInfo] : '';
          this.phoneEditorHtml = res.data.data.Remarks
          this.AddRadioDate = res.data.data.RadioDate ? this.__moment__(res.data.data.RadioDate) : null;
          this.Remarks = res.data.data.Remarks
          this.addAge = res.data.data.StartAge + '-' + res.data.data.EndAge
          this.IssueStatus = res.data.data.State == 1 ? '已发布' : '未发布'
          this.BgMusic = res.data.data.BgMusicUrl
          this.duration = res.data.data.RadioDate.Duration
          this.isShareChecked = res.data.data.isShare == 1 ? true : false
          if (res.data.data.RadioInfo) {
            if (res.data.data.RadioInfo.create_id != res.data.data.CreateUserId) {
              this.isShareChecked = false
              this.ShareDisabled = true
            }
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    _save () {
      let age = this.addAge.split('-')

      let data = {
        RadioName: this.RadioName,
        RadioDate: this.AddRadioDate
          ? this.__moment__(this.AddRadioDate).format('YYYY-MM-DD')
          : '',
        StartAge: parseInt(age[0]),
        EndAge: parseInt(age[1]),
        CreateUserId: userInfo.uid,
        CreateUser: userInfo.name,
        RadioUrl: this.uploadFile[0].FileUrl,
        RadioInfo: JSON.stringify(this.uploadFile[0]),
        Duration: this.duration,
        Remarks: this.Remarks,
        State: this.IssueStatus == '已发布' ? 1 : 0,
        BgMusicUrl: this.BgMusic,
        isShare: this.isShareChecked ? 1 : 0
      }

      let url = 5836141
      if (this.setId || this.setId === 0) {
        data.Id = this.tableData[this.setId].Id
        url = 5836143
      }

      this.$axios.post(url, data, res => {
        if (res.data.code == 1) {
          if (this.setId) {
            this.tableData[this.setId].RadioName = this.RadioName
            this.tableData[this.setId].RadioDate = this.__moment__(
              this.AddRadioDate
            ).format('YYYY-MM-DD')
            this.tableData[this.setId].Remarks = this.Remarks
            this.tableData[this.setId].Age = this.addAge
            this.tableData[this.setId].RadioUrl = this.uploadFile[0].FileUrl
          }
          this.$message.success(res.data.message)
          this._onClose()
          this.RadioName = ''
          this.uploadFile = ''
          this.AddRadioDate = null
          this.Remarks = ''
          this.addAge = []
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    _PageChange (pageNumber) {
      this._infoData(pageNumber)
    },
    _RadioDate (date) {
      this.RadioDate = date
    },
    _search (id) {
      this._infoData(1)
    },
    _infoData (page) {
      let ParentId = '',
        self = this,
        time = ''
      time = this.RadioDate
        ? this.__moment__(this.RadioDate).format('YYYY-MM-DD')
        : ''
      // if(this.relevanceObject){
      //     for (let i = 0;i < self.relevanceObject.length;i++){
      //         ParentId += self.relevanceObject[i].id + ',';
      //     }
      // }
      this.$axios.get(
        5836139,
        {
          Keyword: this.searchInput,
          ParentId: this.relevanceText,
          // StartAge: this.age ? this.age[0] : '',
          // EndAge: this.age ? this.age[1] : '',
          StartAge:this.StartAge,
          EndAge:this.EndAge,
          RadioDate: time,
          Page: page,
          Limit: 10,
          UserId: userInfo.id,
          OrgData: JSON.stringify(this.relevanceObject),
          isShare: this.isShare,
          Label: this.Label
        },
        res => {
          if (res.data.code == 1) {
            this.StatData = res.data.StatData
            this.tableData = res.data.data
            this.count = res.data.count
          } else {
            this.StatData = this.defaultStatData
            this.tableData = []
            this.count = 0
          }
        }
      )
    },
    _reset () {
      this.RadioDate = null
      this.age = []
      this.StartAge = ''
      this.EndAge = ''
      this.relevanceObject = ''
      this.relevanceText = ''
      this.searchInput = ''
      this.isShare = ''
    },
    _relevanceObject (list) {
      let self = this
      this.relevanceObject = list
      for (let i = 0; i < list.length; i++) {
        self.relevanceText += list[i].title + ','
      }
    },
    _delTableRow (i) {
      this.$axios.post(
        5836140,
        { Id: this.tableData[i].Id, UserId: userInfo.uid },
        res => {
          if (res.data.code == 1) {
            this.$message.success('操作成功')
            this.tableData.splice(i, 1)
          } else {
            this.$message.error(res.data.message)
          }
        }
      )
    },
    _uploadFile (e) {
      let self = this
      let formData = null
      let file = e.target.files[0]
      var url = URL.createObjectURL(e.target.files[0])
      var audioElement = new Audio(url)
      var duration
      formData = new FormData()
      formData.append('file', e.target.files[0])
      this.$message.loading('上传中...', 0)
      this.$axios.post(5836148, formData, res => {
        this.$message.destroy()
        if (res.data.code == 1) {
          self.uploadFile = [
            {
              FileName: file.name,
              FileUrl: res.data.url,
              FileType: file.type,
              FileSize: file.size,
              Duration: '未知'
            }
          ]
        }
      })
    },
    _addShow () {
      let self = this
      this.visible = true
      this.isShareShow = true
      this.isShareChecked = false
      setTimeout(function () {
        self.phoneEditor = new E('#websiteEditorElem')
        self.phoneEditor.customConfig.menus = [
          'head',
          'bold',
          'italic',
          'underline',
          'foreColor', // 文字颜色
          'backColor', // 背景颜色
          'list',
          'table',
          'link',
          'justify',
          'image'
        ];
        self.phoneEditor.customConfig.onchange = function (html) {
          self.Remarks = html
        };
        // 创建一个富文本编辑器
        self.phoneEditor.create()
        self.phoneEditor.txt.html(self.phoneEditorHtml)
      })
    },
    _onClose () {
      this.visible = false
      this.uploadFile = ''
      this.setId = ''
      this.phoneEditorHtml = ''
      this.duration = 0
      this.RadioName = ''
      this.AddRadioDate = null
      this.Remarks = ''
      this.addAge = []
      this.isShareShow = true
      this.isShareChecked = false
      this.ShareDisabled = false
      document.getElementById('websiteEditorElem').innerHTML = ''
    },
    _changeAge (value) {
      this.age = value
      if (value) {
          if (value != 'all') {
            let arr = value.split('-');
            this.StartAge = arr[0];
            this.EndAge = arr[1];
        }
      } else {
          this.StartAge = '';
          this.EndAge = '';
      }
    },
    _changeShare (value) {
      this.isShare = value
    },
    _changeLabel (value) {
      this.Label = value
    },
    changeTimeToString (time) {
      var res = ''
      var h = Math.floor(time / 3600)
      if (h < 10) {
        h = '0' + h
      }
      var m = time % 3600
      m = Math.floor(m / 60)
      if (m < 10) {
        m = '0' + m
      }
      var s = time % 60
      if (s < 10) {
        s = '0' + s
      }

      res = h + ':' + m + ':' + s
      return res
    },
    onChangeCheck (v) {
      console.log(v)
    },
    _onMaterialClose () {
      this.materialVisible = false
    },
    _onMaterialShow () {
      this.materialVisible = true
    },
    _changeAddAge (value) {
      this.addAge = value
    },
    _setLabel(data,index,Label){
      console.log(data);
      console.log(index);
      console.log(Label);
      console.log(this.tableData[index]);
      this.$axios.post(8000076, {
        Id: data.Id,
        Label:Label
      }, res => {
        if (res.data.code == 1) {
          this.tableData[index].Label = Label;
          this.tableData[index].LabelText = res.data.data.LabelText;
          this.tableData[index].LabelColor = res.data.data.LabelColor;
          this.tableData[index].LabelBgColor = res.data.data.LabelBgColor;
          this.$message.success(res.data.message);
        } else {
          this.$message.error(res.data.message);
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.record-index {
  width: 100%;
  min-height: 100%;
  padding: 15px;
  background: #fff;
  border-radius: 4px;
  .header {
    margin-top: 10px;
  }
  .container {
    margin-top: 25px;
    .icon-btn {
      font-size: 20px;
      margin-right: 8px;
      cursor: pointer;
    }
    .state-icon {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      vertical-align: middle;
      background: rgb(255, 127, 56);
      margin-right: 4px;
    }
  }
  .person {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1000;
  }
}
.row {
  margin-bottom: 20px;
  .label {
    display: inline-block;
    width: 70px;
    white-space: nowrap;
  }
}
.alert-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  button {
    width: 120px;
  }
}
.audio {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  .audio-title {
    position: relative;
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .audio-upload-time {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      font-size: 12px;
      color: #aaa;
    }
  }
  .audio-file {
    width: 80px;
    padding: 10px 0;
    background: #fafafc;
    text-align: center;
    margin-left: 10px;
  }
  .audio-length {
    color: #aaa;
    font-size: 14px;
  }
}

.status-list {
    > div {
      margin-top: 2px;
      cursor: pointer;
    }
    span {
      position: relative;
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 5px;
      border: 1px solid #ddd;
      border-radius: 50%;
      vertical-align: middle;
    }
    p {
      display: inline-block;
      vertical-align: middle;
    }
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 5px;
      height: 5px;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background: #ddd;
    }
  }

  .Label {
    width: 50px;
    margin: 0px auto;
    border-radius: 5px;
  }

  .Label > span {
    font-weight: bold;
  }

  .Stat{
    display:flex;
    margin-bottom: 25px;
  }
  .Stat > ul > li{
    float:left;
    margin-right: 70px;
  }
  .StatNum{
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }
  .StatText{
    font-size: 10px;
    color: #989595dd;
    text-align: center;
  }

</style>
