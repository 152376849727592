<template>
    <div class="record-index">
        <div class="header flex">
            <div>
                <div style="display:inline-block;position: relative">
                    <person class="person" v-bind="personObj" @getData="_relevanceObject"></person>
                    <a-input v-model="relevanceText" placeholder="区域"></a-input>
                </div>

                <a-date-picker style="width: 150px;margin-left: 15px" v-model="RadioDate" @change="_RadioDate" placeholder="播放时间"/>

                <a-cascader style="width: 120px;margin-left: 15px" :options="ageList" v-model="age" @change="_changeAge" placeholder="年龄段" />

                <a-input placeholder="搜索姓名或故事主题" style="width: 150px;margin-left: 15px" v-model="searchInput"/>

                <a-button style="margin-left: 15px;" @click="_reset">重置</a-button>
                <a-button type="primary" style="margin-left: 15px;" @click="_search">查询</a-button>
            </div>
        </div>

        <div class="container">
            <a-table bordered :columns="tableColumns" :dataSource="tableData" :pagination="false" :rowSelection="rowSelection">
                <div slot="audioFile" slot-scope="text, record, index">
                    <span @click="_skipAudio(record.RadioUrl)" style="color: #1890FF;cursor: pointer">打开>></span>
                </div>
                <div slot="state" slot-scope="text, record, index">
                    <span class="state-icon" :style="text=='已发布'?'background: #75C255':''"></span>
                    <span>{{text}}</span>
                </div>
                <div slot="operation" slot-scope="text, record, index">
                    <i class="iconfont icon-bianji icon-btn" style="font-size: 20px" @click.stop='_setItem(index)'></i>
                    <a-popconfirm title="您确定要删除吗?" @confirm="_delTableRow(index)" okText="确定" cancelText="取消">
                        <i class="iconfont icon-shanchu- icon-btn"></i>
                    </a-popconfirm>
                </div>
            </a-table>
            <div style="margin: 20px 0;" v-if="count>10">
                <a-pagination showQuickJumper :defaultCurrent="1" :total="count" @change="_PageChange" />
            </div>
        </div>
        <div class="alert-footer">
            <a-button style="margin-right:25px" @click.stop="_onClose">取消</a-button>
            <a-button type="primary" @click.stop="_save">确定</a-button>
        </div>
    </div>
</template>

<script>
    import E from 'wangeditor';
    import person from "components/SelectPersonnel/SelectPersonnel"; //选人
    const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'));
    export default {
        name: "MaterialList",
        components: {E,person},
        data(){
            return {
                ageList: [],
                tableColumns: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        width: 70,
                        align: 'center'
                    },
                    {
                        title: '故事主题',
                        dataIndex: 'RadioName',
                        align: 'center'
                    },
                    {
                        title: '年龄段',
                        dataIndex: 'Age',
                        align: 'center'
                    },
                    {
                        title: '音频文件',
                        dataIndex: 'AudioFile',
                        align: 'center',
                        scopedSlots: { customRender: 'audioFile' },
                    },
                    {
                        title: '上传时间',
                        dataIndex: 'CreateTime',
                        align: 'center'
                    },
                    {
                        title: '上传人',
                        dataIndex: 'CreateUser',
                        align: 'center'
                    }
                ],
                tableData: [],
                visible: false,
                phoneEditor: '',
                personObj: {
                    rank: 0, //职级 0为不选，1为选
                    nums: 10000, //选的数量
                    department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: "", //常用联系人 不要常用联系人传空''
                    selectArr: this.relevanceObject //要传入的数据
                },
                relevanceObject: '',
                RadioDate: null,
                age: [],
                relevanceText: '',
                count: 0,
                phoneEditorHtml: '',
                setId: '',
                BgMusicList: [],

                uploadFile: '', //音频文件
                RadioName: '' , //主题名称
                AddRadioDate: null, //播放日期
                Remarks: '', //故事的内容
                addAge: [],
                duration: 0,
                searchInput:'',//创建人搜索

                IssueStatus: '已发布',
                BgMusic: '',
                isShare:1,
                isShareChecked:false
            }
        },
        props:{

        },
        computed: {
            rowSelection() {
              const { selectedRowKeys } = this;
              return {
                hideDefaultSelections: true,
                type: 'radio',
                onChange: (selectedRowKeys, selectedRows) => {
                    this.uploadFile = selectedRows[0]
                },
                getCheckboxProps: record => ({
                  props: {
                    disabled: record.name === 'Disabled User', // Column configuration not to be checked
                    name: record.name,
                  },
                }),
              };
            },
        },
        created(){
            let self = this;
            for(let i = 0;i < 9;i++){
                self.ageList.push( {value: i + 1,label: i + 1,children: []});
                for(let j = i+1;j < 9;j++){
                    self.ageList[i].children.push({value: j + 1,label: j + 1,})
                }
            }
            this.$axios.get(5836147,{},res=>{
                if(res.data.code==1){
                    this.BgMusicList = res.data.data;
                }
            });

            this._infoData(1);
        },
        methods: {
            _skipAudio(audio){
                window.open(audio);
            },
            _delAccessory(){
                this.uploadFile = '';
                this.duration = '';
            },
            _setItem(i){
                this.$axios.get(5836142,{Id:this.tableData[i].Id},res=>{
                    if(res.data.code==1){
                        this._addShow();
                        this.setId = i;
                        this.RadioName = res.data.data.RadioName;
                        this.uploadFile = res.data.data.RadioInfo?[res.data.data.RadioInfo]:'';
                        this.phoneEditorHtml = res.data.data.Remarks;
                        this.AddRadioDate = res.data.data.RadioDate?this.__moment__(res.data.data.RadioDate):null;
                        this.Remarks = res.data.data.Remarks;
                        this.addAge = [res.data.data.StartAge,res.data.data.EndAge];
                        this.IssueStatus = res.data.data.State==1?'已发布':'未发布';
                        this.BgMusic = res.data.data.BgMusicUrl;
                        this.duration = res.data.data.RadioDate.Duration
                        this.isShareChecked = res.data.data.isShare==1?true:false
                    }else{
                        this.$message.error(res.data.message);
                    }
                });
            },
            _PageChange(pageNumber) {
                this._infoData(pageNumber);
            },
            _RadioDate(date){
                this.RadioDate = date;
            },
            _search(){
                this._infoData(1);
            },
            _infoData(page){
                let ParentId = '',self = this,time = '';
                time = this.RadioDate?this.__moment__(this.RadioDate).format('YYYY-MM-DD'):'';
                // if(this.relevanceObject){
                //     for (let i = 0;i < self.relevanceObject.length;i++){
                //         ParentId += self.relevanceObject[i].id + ',';
                //     }
                // }
                this.$axios.get(5836139,{
                    Keyword: this.searchInput,ParentId: '',
                    StartAge: this.age?this.age[0]:'',	
                    EndAge: this.age?this.age[1]:'',
                    RadioDate: time,
                    Page: page,
                    Limit: 10,
                    UserId: userInfo.id,
                    OrgData: JSON.stringify(this.relevanceObject),
                    isShare:1,
                },res=>{
                    if(res.data.code==1){
                        this.tableData = res.data.data;
                        this.count = res.data.count;
                    }else{
                        this.tableData = [];
                        this.count = 0;
                    }
                });
            },
            _reset(){
                this.RadioDate = null;
                this.age = [];
                this.relevanceObject = '';
                this.relevanceText = '';
                this.searchInput = '';
                this.isShare = '';
            },
            _relevanceObject(list) {
                let self = this;
                this.relevanceObject = list;
                for (let i = 0;i < list.length;i++){
                    self.relevanceText += list[i].title + ','
                }

            },
            _delTableRow(i){
                this.$axios.post(5836140,{Id:this.tableData[i].Id,UserId: userInfo.uid},res=>{
                    if(res.data.code==1){
                        this.$message.success('操作成功');
                        this.tableData.splice(i,1);
                    }else {
                        this.$message.error(res.data.message);
                    }
                });
            },
            _addShow(){
                let self = this;
                this.visible = true;
                setTimeout(function () {
                    self.phoneEditor = new E('#websiteEditorElem');
                    self.phoneEditor.customConfig.menus = [
                        'head',
                        'bold',
                        'italic',
                        'underline',
                        'foreColor',  // 文字颜色
                        'backColor',  // 背景颜色
                        'list',
                        'table',
                        'link',
                        'justify',
                        'image'
                    ];
                    self.phoneEditor.customConfig.onchange = function (html) {
                      self.Remarks = html;
                    };
                    // 创建一个富文本编辑器
                    self.phoneEditor.create();
                    self.phoneEditor.txt.html(self.phoneEditorHtml);
                })

            },
            _onClose(){
                this.$emit('fathermethod','')
            },
            _changeAge(value){
                this.age = value;
            },
            _changeShare(value){
                this.isShare = value;
            },
            changeTimeToString(time) {
                var res = '';
                var h = Math.floor(time / 3600);
                if (h < 10) {
                    h = '0' + h;
                }
                var m = time % 3600;
                m = Math.floor(m / 60);
                if (m < 10) {
                    m = '0' + m;
                }
                var s = time % 60;
                if (s < 10) {
                    s = '0' + s;
                }

                res = h + ':' + m + ':' + s;
                return res;
            },
            _save(){
                this.$emit('fathermethod',this.uploadFile)
            }
        }
    }
</script>

<style scoped lang="less">
    .record-index{
        width: 100%;
        min-height: 100%;
        padding: 15px;
        background: #fff;
        border-radius: 4px;
        .header{
            margin-top: 10px;
        }
        .container{
            margin-top: 25px;
            .icon-btn{
                font-size: 20px;
                margin-right: 8px;
                cursor: pointer;
            }
            .state-icon{
                display: inline-block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                vertical-align: middle;
                background: rgb(255, 127, 56);
                margin-right: 4px;
            }
        }
        .person{
            position: absolute !important;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 1000
        }
    }
    .row{
        margin-bottom: 20px;
        .label{
            display: inline-block;
            width: 70px;
            white-space: nowrap;
        }
    }
    .alert-footer{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
        button{
            width: 120px;
        }
    }
    .audio{
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-radius: 5px;
        font-size: 16px;
        .audio-title{
            position: relative;
            width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .audio-upload-time{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                font-size: 12px;
                color: #aaa;
            }
        }
        .audio-file{
            width: 80px;
            padding: 10px 0;
            background: #FAFAFC;
            text-align: center;
            margin-left: 10px;
        }
        .audio-length{
            color: #aaa;
            font-size: 14px;
        }
    }
</style>